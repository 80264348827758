// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { deleteDoc, getFirestore, collection, query, where, addDoc, getDocs, setDoc, doc, updateDoc, serverTimestamp, increment, getDoc, onSnapshot } from "firebase/firestore";
import { getAuth, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB0jSOBpfJjTZchRJkAaO0rDrlue1yUa0w",
  authDomain: "daira-bussines.firebaseapp.com",
  databaseURL: "https://daira-bussines.firebaseio.com",
  projectId: "daira-bussines",
  storageBucket: "daira-bussines.appspot.com",
  messagingSenderId: "327737780316",
  appId: "1:327737780316:web:c6a6605cfab89a10",
  measurementId: "G-NS0YL1YNR6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const analytics = getAnalytics(app);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

/*const firebaseNode = firebase.initializeApp(config);
const db = firebase.firestore();
const dbReal = firebase.database()
const storage = firebase.storage();
//const provider1 = new firebase.auth.GoogleAuthProvider();
const auth = getAuth();*/


export {
    app, analytics, db, collection, addDoc, setDoc, doc, updateDoc, serverTimestamp,
    query, where, getDocs,
    increment, getDoc, onSnapshot, auth, provider, getAuth, signInWithPopup,
    getRedirectResult, onAuthStateChanged, GoogleAuthProvider, deleteDoc
}
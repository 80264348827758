<template>
  <div>
    <!-- 
      <b-button v-b-modal.modal-center>Launch demo modal</b-button>
    -->

    <b-modal
      id="modal"
      ref="mymodal"
      title="Atención Rápida"
      :footer-bg-variant="footerBgVariant"
      centered
    >
      <b-container class="bv-example-row" fluid v-if="etapa == 0">
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <p class="my-4">Selecciona lo que estás buscando.</p>
          </b-col>
        </b-row>
        <b-row class="mb-1 text-center mt-n3">
          <b-col class="mb-1">
            <div
              @click="paso('design')"
              class="p-2 servicios"
              style="background-color: aqua"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="currentColor"
                class="bi bi-sunglasses"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 5a2 2 0 0 0-2 2v.5H.5a.5.5 0 0 0 0 1H1V9a2 2 0 0 0 2 2h1a3 3 0 0 0 3-3 1 1 0 1 1 2 0 3 3 0 0 0 3 3h1a2 2 0 0 0 2-2v-.5h.5a.5.5 0 0 0 0-1H15V7a2 2 0 0 0-2-2h-2a2 2 0 0 0-1.888 1.338A1.99 1.99 0 0 0 8 6a1.99 1.99 0 0 0-1.112.338A2 2 0 0 0 5 5H3zm0 1h.941c.264 0 .348.356.112.474l-.457.228a2 2 0 0 0-.894.894l-.228.457C2.356 8.289 2 8.205 2 7.94V7a1 1 0 0 1 1-1z"
                />
              </svg>

              <span class="p-2">Diseño Gráfico</span>
            </div>

            <!-- 
              <b-img src="https://picsum.photos/1024/400/?image=41" fluid-grow alt="Responsive image"></b-img>
            -->
          </b-col>
          <b-col class="mb-1">
            <div
              @click="paso('web')"
              class="p-2 servicios"
              style="background-color: antiquewhite"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-pc-display-horizontal"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"
                />
              </svg>

              <span class="p-3">Web / App </span>
            </div>
          </b-col>
          <b-col class="mb-1">
            <div @click="paso('Ilustracion')" class="p-2 servicios">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                fill="currentColor"
                class="bi bi-bicycle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 4.5a.5.5 0 0 1 .5-.5H6a.5.5 0 0 1 0 1v.5h4.14l.386-1.158A.5.5 0 0 1 11 4h1a.5.5 0 0 1 0 1h-.64l-.311.935.807 1.29a3 3 0 1 1-.848.53l-.508-.812-2.076 3.322A.5.5 0 0 1 8 10.5H5.959a3 3 0 1 1-1.815-3.274L5 5.856V5h-.5a.5.5 0 0 1-.5-.5zm1.5 2.443-.508.814c.5.444.85 1.054.967 1.743h1.139L5.5 6.943zM8 9.057 9.598 6.5H6.402L8 9.057zM4.937 9.5a1.997 1.997 0 0 0-.487-.877l-.548.877h1.035zM3.603 8.092A2 2 0 1 0 4.937 10.5H3a.5.5 0 0 1-.424-.765l1.027-1.643zm7.947.53a2 2 0 1 0 .848-.53l1.026 1.643a.5.5 0 1 1-.848.53L11.55 8.623z"
                />
              </svg>
              <span class="p-3">Ilustración</span>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div v-else-if="etapa == 1">
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <p class="my-4">Escribe lo que necesitas...</p>
          </b-col>
        </b-row>
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <b-form-textarea
              v-model="text"
              placeholder="Escribe aquí..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <!--
              <div class="mt-2">Value: {{ text }}</div>
            -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button block variant="outline-primary" @click="paso(text)"
              >Continuar</b-button
            >
          </b-col>
        </b-row>
      </div>

      <div v-else-if="etapa == 2">
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <p class="my-4">
              Por último, escribe tu número whatsapp o correo electrónico.
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <b-form-input
              v-model="contacto"
              placeholder="Escribe aquí..."
            ></b-form-input>
            <!--
              <div class="mt-2">Value: {{ text }}</div>
            -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button block variant="outline-primary" @click="paso(contacto)"
              >Finalizar</b-button
            >
          </b-col>
        </b-row>
      </div>

      <div v-else-if="etapa == 3">
        <b-row class="mb-1 mt-n4 text-center">
          <b-col>
            <p class="my-4">
              Gracias por tus respuestas, estaremos en contacto lo antes
              posible.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button block variant="outline-primary" @click="$bvModal.hide('modal')"
              >Cerrar</b-button
            >
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
       
        <div class="w-100 colorApp" v-show="final">
          <b-button
            variant="outline-light"
            class="float-right"
            @click="$bvModal.hide('modal')"
            block
          >
            Salir del Asistente / Exit the Assistant
          </b-button>
        </div>
      </template>
      <!-- 
        <b-button class="mt-3" block @click="$bvModal.hide('modal-center')"
          >Close Me</b-button
        >
      -->
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "vue-modal",
  data: () => ({
    footerBgVariant: "primary",
    text: "",
    etapa: 0,
    contacto: "",
    final: true,
  }),
  mounted() {
    //this.$refs.mymodal.show();
  },
  methods: {
    paso(llega) {
      console.log(llega);
      this.etapa++;
      if (this.etapa > 0) this.final = true;
    },
  },
};
</script>


<style>
.servicios {
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: rgb(63, 204, 255);
  border-radius: 25px;
}

.colorApp {
  color: rgba(216, 216, 216, 1);
}
</style>
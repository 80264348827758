<template>
  <div>
    <div class="container">
      <div class="nav-scroller py-1 mb-2">
        <nav class="nav d-flex justify-content-between">
          <a class="p-2 text-muted" href="#">Mantenimiento Digital</a>
          <a class="p-2 text-muted" href="#">Ilustración Digital</a>
          <a class="p-2 text-muted" href="#">Apps Moviles</a>
          <a class="p-2 text-muted" href="#">Aplicaciones Web</a>
        </nav>
      </div>
      <!-- CONTENIDO PAGE -->
      <HelloWorld />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>

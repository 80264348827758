<template>
  <div>
    <div class="container">
      <div class="nav-scroller py-1 mb-2">
        <nav class="nav d-flex justify-content-between">
          <a class="p-2 text-muted" href="#">Digital Maintenance</a>
          <a class="p-2 text-muted" href="#">Digital Illustration</a>
          <a class="p-2 text-muted" href="#">Mobile Apps</a>
          <a class="p-2 text-muted" href="#">Web Applications</a>
        </nav>
      </div>
    </div>

    <HelloWorld />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld-ingles.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>

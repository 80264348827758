<template>
  <div>
    <!-- DIGITAL SERVICES Escritorio -->
    <b-container class="bv-example-row">
      <b-row>
        <div class="contactosImagen"></div>
        <!-- PRIMERA SECCION -->
        <b-col lg="6" sm="12" class="textoInicio">
          <div
            class="row services-header p-3 pb-md-4 mx-auto text-center justify-content-center border-bottom"
          >
            <div>
              <h1 class="featurette-heading fw-normal lh-1">
                Diseño, Web y Apps
              </h1>
            </div>
            <div class="pricing-header mt-2">
              <p class="fs-8 text-muted">
                Sabemos que los servicios digitales a veces pueden parecer
                complicados debido a los tecnicismos y conceptos extraños que
                existen en la industria, pero en JMVR nos enfocamos en ser
                prácticos. Nuestro objetivo es hacer felices a nuestros
                clientes, ¡sé uno de ellos!
                <br />
                <a
                  href="https://wa.me/573006386111"
                  class="texto_azul font-weight-bold sinLinea"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                    />
                  </svg>

                  Escríbenos por Whatsapp
                </a>

                <br /><br />
                Service available in English or Spanish

                <br /><br />

                <a
                  href="tel:+573006386111"
                  class="texto_azul font-weight-bold sinLinea"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    fill="currentColor"
                    class="bi bi-phone"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                    />
                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>

                  Call - Llamar
                </a>
              </p>

              <router-link to="/en">Website in English</router-link>
            </div>
          </div>
        </b-col>
        <!-- SEGUNDA SECCION-->
        <b-col lg="6" md="12">
          <b-container class="contactanos" fluid>
            <b-row class="my-1">
              <h3 class="display-4 fw-normal text-white p-1 ml-3">Escríbeme</h3>
            </b-row>
            <div v-if="bloquoEnvio">
              <b-row class="my-1">
                <b-col sm="12" class="p-3 fs-8">
                  ¡Hola!, no seas tímido, déjame saber que servicio necesitas o
                  en qué te puedo ayudar. En breve te estaré respondiendo, nos
                  vemos pronto.
                </b-col>
              </b-row>

              <b-row class="my-1 p-1">
                <b-col sm="12" class="">
                  <b-form-input
                    v-model="nombre"
                    class="p-3"
                    id="input-small"
                    size="sm"
                    placeholder="Tu nombre"
                    style="border-radius: 20px"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1 p-1">
                <b-col sm="12" class="">
                  <b-form-input
                    type="number"
                    v-model="movil"
                    class="p-3"
                    id="input-small"
                    size="sm"
                    placeholder="Tu WhatsApp o movil"
                    style="border-radius: 20px"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-1 p-2">
                <b-col sm="12" class="">
                  <b-form-select
                    v-model="selected"
                    :options="options"
                    size="sm"
                    style="border-radius: 20px"
                    required
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-1 p-2">
                <b-col sm="12" class="">
                  <b-form-textarea
                    v-model="comentario"
                    id="textarea"
                    placeholder="¡Escríbe lo que quieras!"
                    rows="3"
                    max-rows="6"
                    style="border-radius: 20px"
                    required
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="my-1 p-2">
                <b-col sm="12" class="">
                  <b-button
                    @click="saliendo()"
                    :disabled="!bloquoEnvio"
                    variant="light"
                    >Enviar. Ya nos vemos</b-button
                  >
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col sm="12" class="p-3 fs-8">
                  Perfecto, estaré enviando por Whatsapp la información que has
                  pedido.
                  <br /><br />
                  Gracias
                </b-col>
              </b-row>
            </div>
          </b-container>
        </b-col>
      </b-row>
    </b-container>

    <!-- 1 SECCIÓN -->
    <div
      class="services-header mt-5 p-3 pb-md-4 mx-auto text-center border-bottom border-opacity-10"
    >
      <div class="row featurette">
        <div class="col-md-7 my-auto">
          <h2 class="featurette-heading fw-normal lh-1">
            Diseño de <span class="text-muted"> Logos </span>
          </h2>
          <p class="lead">
            Diseño profesional de logos, isotipos, imagotipo e isologo.
            Materializamos tu concepto para que puedas llevar tu idea al mundo
            digital y físico.
          </p>
          <br /><br />
        </div>

        <div class="col-md-5">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2F01.png?alt=media&token=8e3e8e69-145f-4cb8-b691-33a749fb3747"
            class="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>
      <!-- 
        <div>
  
          <galeriaMuestra />
        </div>
      -->
    </div>

    <!-- 2 SECCIÓN -->
    <div
      class="services-header row featurette mt-5 p-3 pb-md-4 mx-auto text-center border-bottom"
    >
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading fw-normal lh-1">
          WebSite <span class="text-muted">o Landing Page</span>
        </h2>
        <p class="lead">
          Diseñamos y programamos tu sitio web. Puede ser algo para un solo
          producto, empresa, evento... hay varias opciones.
        </p>
        <br /><br />
      </div>
      <div class="col-md-5 order-md-1">
        <img
          src="https://img.freepik.com/vector-gratis/ilustracion-concepto-creador-sitio-web_114360-3315.jpg?w=1060&t=st=1667912310~exp=1667912910~hmac=06b2bdfa34e453534bc586108d72c58c70b6716a8badaab05edbf699361ab3de"
          class="img-fluid"
          alt="Responsive image"
        />
      </div>
    </div>

    <!-- 3 SECCIÓN  -->
    <div
      class="services-header mt-5 p-3 pb-md-4 mx-auto text-center border-bottom border-opacity-10"
    >
      <div class="row featurette">
        <div class="col-md-7 my-auto">
          <h2 class="featurette-heading fw-normal lh-1">
            Mantenimiento <span class="text-muted"> para Aplicaciones web</span>
          </h2>
          <p class="lead">
            Actualizamos tu proyecto o desarrollo en marcha. Algo de
            mantenimiento no viene nada mal.
          </p>
          <br /><br />
        </div>
        <div class="col-md-5">
          <img
            src="https://img.freepik.com/vector-gratis/gerentes-relaciones-publicas-coworking-comercializadores-internet_335657-3064.jpg?w=1800&t=st=1667912411~exp=1667913011~hmac=2153774eaff0e023bc65ffabf18b0c1a2e31aabc6b2c3d18ed6f68700a0379e8"
            class="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>
    </div>

    <!-- 4 SECCIÓN -->
    <div
      class="services-header row featurette mt-5 p-3 pb-md-4 mx-auto text-center border-bottom"
    >
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading fw-normal lh-1">
          Ilustración <span class="text-muted"> Digital</span>
        </h2>
        <p class="lead">
          Quieres llevar tu bocetos, material educativo, didáctico, planos,
          cartas de producción, etc. A un material digital. Ayudamos en procesos
          de digitalización.
        </p>
        <br /><br />
      </div>
      <div class="col-md-5 order-md-1">
        <img
          src="https://img.freepik.com/vector-gratis/ilustracion-concepto-herramienta-pluma_114360-478.jpg?w=1060&t=st=1667912492~exp=1667913092~hmac=34b643fcf6d1f0dabae482dd9af44f017a661a8b500a92518553ea95fb939375"
          class="img-fluid"
          alt="Responsive image"
        />
      </div>
    </div>

    <!-- 5 SECCIÓN -->
    <div
      class="services-header mt-5 p-3 pb-md-4 mx-auto text-center border-bottom border-opacity-10"
    >
      <div class="row featurette">
        <div class="col-md-7 my-auto">
          <h2 class="featurette-heading fw-normal lh-1">
            Apps <span class="text-muted"> Android or iOS</span>
          </h2>
          <p class="lead">
            Diseño y programación de aplicaciones moviles. Catálogos, pedidos,
            control de procesos, entre otros procesos.
          </p>
          <br /><br />
        </div>
        <div class="col-md-5">
          <img
            src="https://img.freepik.com/vector-gratis/mano-humana-sosteniendo-telefono-movil-mensajes-texto_74855-6531.jpg?w=1380&t=st=1667912564~exp=1667913164~hmac=9580b90c5123b4e6df11783e64bc782bc0c2ea1ce6dcd33d2f435180db109da9"
            class="img-fluid"
            alt="Responsive image"
          />
        </div>
      </div>
    </div>

    <!-- 6 SECCIÓN -->
    <div
      class="services-header row featurette mt-5 p-3 pb-md-4 mx-auto text-center border-bottom"
    >
      <div class="col-md-7 order-md-2">
        <h2 class="featurette-heading fw-normal lh-1">
          Aplicaciones Web <span class="text-muted"> Control de Procesos</span>
        </h2>
        <p class="lead">
          No puedes dormir, porque no sabes si tu empresa está dando ganancias?
          <br /><br />
          Si deseas controlar un proceso para aumentar la productividad o
          eficiencia del negocio.
        </p>
        <br /><br />
      </div>
      <div class="col-md-5 order-md-1">
        <img
          src="https://img.freepik.com/vector-premium/mujer-dibujada-mano-cama-insomnio-aislado_338906-70.jpg?w=1060"
          class="img-fluid"
          alt="Responsive image"
        />
      </div>
    </div>

    <!-- 6 SECCIÓN CLIENTES-->
    <div class="mx-auto text-center border-bottom">
      <h2 class="featurette-heading fw-normal lh-1">
        Clientes <span class="text-muted"> en los últimos 15 años</span>
      </h2>

      <b-row class="mt-10">
        <b-col>
          <b-img
            style="width: 30%"
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2Fkisspng-unicef-angola-united-nations-logo-unicef-burundi-5b093fd0c948b9.7423819715273328168245.png?alt=media&token=dc9f38b4-60b8-4d74-a1fc-e56957091188"
            fluid
            alt="Responsive image"
          ></b-img>
        </b-col>
        <b-col>
          <b-img
            style="width: 50%"
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2FG4S_logotyp.jpg?alt=media&token=5ac9eeb5-68e0-4160-baf6-d5ce5d18ff99"
            fluid
            alt="Responsive image"
          ></b-img>
        </b-col>
        <b-col>
          <b-img
            style="width: 50%"
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2FAlpina_S.A._logo.svg.png?alt=media&token=0153c432-6255-4a9f-9c48-6550195c545a"
            fluid
            alt="Responsive image"
          ></b-img>
        </b-col>
      </b-row>

      <br /><br />
    </div>

    <!-- 7 SECCIÓN - PREMIOS -->
    <div
      class="services-header row featurette mt-5 p-3 pb-md-4 mx-auto text-center border-bottom"
    >
      <div class="row">
        <div class="col-sm pa-3">
          <b-img
            rounded="circle"
            width="200%"
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2Fmedalla-1.png?alt=media&token=670719c4-307c-4c70-b4c6-cbd91c34325a"
            alt="Premios Andicom 2018"
          >
          </b-img>
          <div class="card-body">
            <h5 class="card-title mt-3">Premio 2018</h5>
            <p class="card-text text-muted">
              <mark>Impacto Social </mark>, Durante el evento internacional
              Transformación Digital Empresarial PwC, Andicom y Cintel se ocupa
              el 2do puesto.
            </p>

            <b-button
              variant="outline-primary"
              href="https://cintel.co/segundo-ano-consecutivo-pwc-cintel-reconocieron-esfuerzos-transformacion-digital-empresarial/"
              target="_blank"
            >
              Ver más
            </b-button>
          </div>
        </div>
        <div class="col-sm pa-3">
          <b-img
            rounded="circle"
            width="200%"
            src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2Fmedalla-1.png?alt=media&token=670719c4-307c-4c70-b4c6-cbd91c34325a"
            alt="Premios Andicom 2019"
          ></b-img>
          <div class="card-body">
            <h5 class="card-title mt-3">Premio 2019</h5>
            <p class="card-text text-muted">
              <mark>Emprendimiento Digital del año</mark>, Durante el evento
              internacional Transformación Digital Empresarial PwC, Andicom y
              Cintel se ocupa el 2do puesto.
            </p>

            <b-button
              variant="outline-primary"
              href="https://cintel.co/tercer-ano-pwc-cintel-reconocen-esfuerzos-transformacion-digital-empresarial/"
              target="_blank"
            >
              Ver más
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <b-row>
        <b-col>
          <a
            href="https://play.google.com/store/apps/details?id=com.javiervanegas.javiervanegas&hl=es&gl=US"
            target="_blank"
          >
            <b-img
              width="250%"
              fluid
              src="https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2Fuploadsgoogle-play-generic%20(1).png?alt=media&token=79cedbf8-a232-4d96-966d-e71c59224847"
              alt="JMVR app movil"
            >
            </b-img>
          </a>
        </b-col>
      </b-row>
    </div>

    <!-- 8 SECCIÓN - METODOS DE PAGO -->
    <div
      class="services-header row featurette mt-1 p-3 pb-md-4 mx-auto text-center border-bottom"
    >
      <div class="row">
        <div class="col-sm pa-3">
          <h3>Métodos de pago / Payment Methods</h3>
          <h6>Todos los medios de pago</h6>

          <img
            class="img-fluid"
            width="50%"
            src="https://img.freepik.com/vector-gratis/ilustracion-concepto-moneda_114360-5507.jpg?w=1380&t=st=1667917711~exp=1667918311~hmac=b12228378f21da5abf3b975d6f9d1d9c72ed00612ee0f650e13f69b52c7d5632"
            alt="Premios Andicom 2019"
          />
        </div>
      </div>
      <!-- 
        <div class="row">
          <div class="col-sm pa-3">
            <div class="card-body">
              <h5 class="card-title mt-3">Transferencia / Transfer</h5>
              <p class="card-text text-muted">
                Euros
                <br /><br />
                <mark>BIC</mark> TRWIBEB1XXX <br /><br />
                <mark>IBAN</mark> BE81 9674 5080 3624 <br /><br />
                <mark>Dirección</mark> Avenue Louise 54, Room S52 Brussels 1050
                Belgium <br /><br />
              </p>
            </div>
          </div>
          <div class="col-sm pa-3">
            <div class="card-body">
              <h5 class="card-title mt-3">Transferencia / Transfer</h5>
  
              <p class="card-text text-muted">
                Dollar
                <br /><br />
                <mark>Route number</mark> 084009519 <br /><br />
                <mark>Account number</mark> 9600009737985213 <br /><br />
                <mark>Account type</mark> Corriente <br /><br />
                <mark>Dirección</mark> 30 W. 26th Street, Sixth Floor New York NY
                10010 United States <br /><br />
              </p>
            </div>
          </div>
          <div class="col-sm pa-3">
            <h5 class="card-title mt-3">Pago en Linea / Pay on line</h5>
            <a
              href="https://secure.payco.co/checkoutopen/49158"
              target="_blank"
              class="btn button_pago"
            >
              <img
                class="img-fluid"
                width="60%"
                src="https://img.freepik.com/vector-gratis/ilustracion-concepto-informacion-pago_114360-2886.jpg?w=1380&t=st=1667918132~exp=1667918732~hmac=8a0dd6088ffcd5aa73b0c7c07c23c6062b620eec0103ea5c49cd94730e7c6352"
                alt="Pago en linea"
              />
            </a>
          </div>
        </div>
      -->
    </div>

    <!-- 9 SECCIÓN - FOOTER -->
    <div class="pa-4">
      <div class="fluid-container footer">
        <p class="text-center">
          Copyright &copy; 2022, Javier Vanegas Ramirez - Rehobot G.E. All
          Rights Reserved.
        </p>

        <p>Contac +34 687725887 -- vanegas.r.javier@gmail.com || v.1.9</p>
      </div>
    </div>

    <a
      href="https://wa.me/573006386111"
      class="whatsapp"
      target="_blank"
      style="color: #ffffff"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35px"
        height="35px"
        fill="currentColor"
        class="bi bi-whatsapp"
        viewBox="0 0 16 16"
        style="margin-top: 5"
      >
        <path
          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
        />
      </svg>
    </a>

    <a href="tel:+573006386111" class="llamarIcono" target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35px"
        height="35px"
        fill="currentColor"
        class="bi bi-phone"
        viewBox="0 0 16 16"
      >
        <path
          d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
        />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg>
    </a>
    <!-- 
    -->

    <Modal />
  </div>
</template>

<script>
import { db, collection, addDoc, serverTimestamp } from "@/Utils/firebase";
import Modal from "@/views/modal/roobot.vue";
//import galeriaMuestra from "@/views/slider/slider-01.vue";

export default {
  name: "HelloWorld",
  components: {
    Modal,
    //galeriaMuestra,
  },
  data: () => ({
    selected: null,
    options: [
      { value: null, text: "Selecciona algo" },
      { value: "disign", text: "Diseño Gráfico" },
      { value: "progamacion", text: "Programación" },
      { value: "ilustracion", text: "Ilustración" },
      { value: "publicidad", text: "Publicidad" },
      { value: "burradas", text: "Cualquier otra cosa, vamos" },
    ],
    nombre: "",
    movil: "",
    comentario: "",
    bloquoEnvio: true,
  }),
  mounted() {
    //https://www.example.com/?servicio=en
    if (
      this.$route.query.servicio != null ||
      this.$route.query.servicio != undefined
    ) {
      var tipiSer = this.$route.query.servicio;
      if (tipiSer == "en") {
        this.$router.push("/en");
      }
    }
  },
  methods: {
    async saliendo() {
      this.bloquoEnvio = false;

      await addDoc(collection(db, "solicitudesWeb"), {
        servicio: this.selected,
        nombre: this.nombre,
        movil: this.movil,
        comentario: this.comentario,
        timestamp: serverTimestamp(),
      });

      this.selected = null;
      this.nombre = "";
      this.movil = "";
      this.comentario = "";
      this.bloquoEnvio = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.contactosImagen {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/daira-bussines.appspot.com/o/newSite%2FsitioWeb%2Filustracion-dibujado-mano-tienda-web-comercio-electronico_107791-10966.webp?alt=media&token=8acdc5be-6d70-46dd-b101-bb6929985106");
  position: absolute;
  height: 100%;
  width: 0%;
  opacity: 0.06;
}
.contactanos {
  /*border: 5px solid #00baee;*/
  background-color: rgba(0, 169, 230, 0.7); /*#00baee*/
  color: #005377;
  height: 100%;
  border-radius: 40px;
  font-weight: bold;
}

.textoInicio {
  height: 1%;
  /*margin-top: 5%;*/
}
</style>
